'use client';

import { MY_SERMONS_SEARCH_TYPE, SEARCH_TYPE } from '@/types/sermons';
import classNames from 'classnames';
import Link from 'next/link';
import React, { useEffect, useRef } from 'react';
import { BsCaretDownFill } from 'react-icons/bs';
import { Tooltip } from 'react-tooltip';
import BaseButton from '../atoms/base-button';
import { useAppState } from '../providers/state-provider';

interface SearchTypeDropdownProps {
  searchType: string;
  setSearchType: React.Dispatch<SEARCH_TYPE | MY_SERMONS_SEARCH_TYPE>;
  searchTypeOpen: boolean;
  setSearchTypeOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function SearchTypeDropdown({
  searchType,
  setSearchType,
  searchTypeOpen,
  setSearchTypeOpen,
}: SearchTypeDropdownProps) {
  const {
    state: {
      mySermons: { isMySermons },
    },
  } = useAppState();
  const searchTypeButtonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    const handler = (e: MouseEvent) => {
      if (!searchTypeButtonRef.current?.contains(e.target as Node)) {
        setSearchTypeOpen(false);
      }
    };
    document.addEventListener('click', handler);

    return () => {
      document.removeEventListener('click', handler);
    };
  }, [setSearchTypeOpen]);

  return (
    <div className='relative inline-block w-full lg:col-span-3'>
      <BaseButton
        buttonType={isMySermons ? 'red' : 'default'}
        type='button'
        className='search-type-button inline-flex h-8 w-full items-center justify-center truncate'
        onClick={() => setSearchTypeOpen(!searchTypeOpen)}
        ref={searchTypeButtonRef}
      >
        {searchType} <BsCaretDownFill className='ml-1 inline-block' />
      </BaseButton>
      <nav
        className={classNames(
          'absolute top-8 z-50 border border-neutral-300 bg-white text-sm',
          {
            hidden: !searchTypeOpen,
          },
        )}
      >
        {' '}
        {!isMySermons && (
          <>
            <ul className='grid grid-cols-1'>
              <li
                className='cursor-pointer px-4 py-1 pt-2 text-sermons-dark hover:bg-neutral-100 hover:underline'
                onClick={() => setSearchType('Sermon Prep')}
                data-tooltip-id='sermon-prep-tooltip'
              >
                Sermon Prep
                <Tooltip
                  id='sermon-prep-tooltip'
                  place='right'
                  className='z-50'
                >
                  Prepare sermons with relevant materials.
                </Tooltip>
              </li>
              <li
                className='cursor-pointer px-4 py-1 text-sermons-dark hover:bg-neutral-100 hover:underline'
                onClick={() => setSearchType('Illustrations · Quotes · Humor')}
                data-tooltip-id='illustrations-tooltip'
              >
                Illustrations · Quotes · Humor
                <Tooltip
                  id='illustrations-tooltip'
                  place='right'
                  className='z-50'
                >
                  Find illustrations, quotes, and humor.
                </Tooltip>
              </li>
              <li
                className='cursor-pointer px-4 py-1 text-sermons-dark hover:bg-neutral-100 hover:underline'
                onClick={() => setSearchType('Commentary')}
                data-tooltip-id='commentary-tooltip'
              >
                Commentary
                <Tooltip id='commentary-tooltip' place='right' className='z-50'>
                  Access biblical commentaries.
                </Tooltip>
              </li>
              <li className='cursor-pointer text-sermons-dark hover:bg-neutral-100 hover:underline'>
                <Link
                  className='inline-block w-full px-4 py-1'
                  onClick={() => setSearchType('Dictionary')}
                  href='/dictionary'
                  data-tooltip-id='dictionary-tooltip'
                >
                  Dictionary
                  <Tooltip
                    id='dictionary-tooltip'
                    place='right'
                    className='z-50'
                  >
                    Look up definitions and explanations.
                  </Tooltip>
                </Link>
              </li>
              <li className='cursor-pointer text-sermons-dark hover:bg-neutral-100 hover:underline'>
                <Link
                  className='inline-block w-full px-4 py-1'
                  onClick={() => setSearchType('Lectionary')}
                  href='/lectionary'
                  data-tooltip-id='lectionary-tooltip'
                >
                  Lectionary
                  <Tooltip
                    id='lectionary-tooltip'
                    place='right'
                    className='z-50'
                  >
                    Access lectionary resources.
                  </Tooltip>
                </Link>
              </li>
              <li className='cursor-pointer text-sermons-dark hover:bg-neutral-100 hover:underline'>
                <Link
                  className='inline-block w-full px-4 py-1'
                  onClick={() => setSearchType('Bible')}
                  href='/bible'
                  data-tooltip-id='bible-tooltip'
                >
                  Bible
                  <Tooltip id='bible-tooltip' place='right' className='z-50'>
                    Search the Bible and compare Bible translations.
                  </Tooltip>
                </Link>
              </li>
              <li
                className='cursor-pointer px-4 py-1 text-sermons-dark hover:bg-neutral-100 hover:underline'
                onClick={() => setSearchType('Media')}
                data-tooltip-id='media-tooltip'
              >
                Media
                <Tooltip id='media-tooltip' place='right' className='z-50'>
                  Find media resources to display on the big screen.
                </Tooltip>
              </li>
              <li
                className='cursor-pointer px-4 py-1 text-sermons-dark hover:bg-neutral-100 hover:underline'
                onClick={() => setSearchType("Children's Sermons")}
                data-tooltip-id='childrens-sermons-tooltip'
              >
                Children&apos;s Sermons
                <Tooltip
                  id='childrens-sermons-tooltip'
                  place='right'
                  className='z-50'
                >
                  Find resources for children&apos;s sermons.
                </Tooltip>
              </li>
              <li className='cursor-pointer text-sermons-dark hover:bg-neutral-100 hover:underline'>
                <Link
                  className='inline-block w-full px-4 py-1 pb-2'
                  onClick={() => setSearchType("Children's Bulletins")}
                  href='/childrens-bulletins'
                  data-tooltip-id='childrens-bulletins-tooltip'
                >
                  Children&apos;s Bulletins
                  <Tooltip
                    id='childrens-bulletins-tooltip'
                    place='right'
                    className='z-50'
                  >
                    Access children&apos;s bulletins.
                  </Tooltip>
                </Link>
              </li>
            </ul>
            <hr />
            <ul className='grid grid-cols-1'>
              <li className='cursor-pointer text-sermons-dark hover:bg-neutral-100 hover:underline'>
                <Link
                  className='inline-block w-full px-4 py-1'
                  onClick={() => setSearchType('Sermon Series')}
                  href='/sermon-series'
                  data-tooltip-id='sermon-series-tooltip'
                >
                  Sermon Series
                  <Tooltip
                    id='sermon-series-tooltip'
                    place='right'
                    className='z-50'
                  >
                    Explore various sermon series.
                  </Tooltip>
                </Link>
              </li>
              <li
                className='cursor-pointer px-4 py-1 text-sermons-dark hover:bg-neutral-100 hover:underline'
                onClick={() => setSearchType('Bulletin & Sermon Aids')}
                data-tooltip-id='bulletin-sermon-aids-tooltip'
              >
                Bulletin & Sermon Aids
                <Tooltip
                  id='bulletin-sermon-aids-tooltip'
                  place='right'
                  className='z-50'
                >
                  Find bulletin and sermon aids.
                </Tooltip>
              </li>
              <li className='cursor-pointer text-sermons-dark hover:bg-neutral-100 hover:underline'>
                <Link
                  className='inline-block w-full px-4 py-1'
                  onClick={() => setSearchType('King Duncan')}
                  href='/king-duncan'
                  data-tooltip-id='king-duncan-tooltip'
                >
                  King Duncan
                  <Tooltip
                    id='king-duncan-tooltip'
                    place='right'
                    className='z-50'
                  >
                    Access King Duncan resources.
                  </Tooltip>
                </Link>
              </li>
              <li className='cursor-pointer text-sermons-dark hover:bg-neutral-100 hover:underline'>
                <Link
                  className='inline-block w-full px-4 py-1'
                  onClick={() => setSearchType('Authors A-Z')}
                  href='/author-list'
                  data-tooltip-id='authors-a-z-tooltip'
                >
                  Authors A-Z
                  <Tooltip
                    id='authors-a-z-tooltip'
                    place='right'
                    className='z-50'
                  >
                    Browse authors A-Z.
                  </Tooltip>
                </Link>
              </li>
              <li
                className='cursor-pointer px-4 py-1 text-sermons-dark hover:bg-neutral-100 hover:underline'
                onClick={() => setSearchType('Dramas')}
                data-tooltip-id='dramas-tooltip'
              >
                Dramas
                <Tooltip id='dramas-tooltip' place='right' className='z-50'>
                  Find dramas.
                </Tooltip>
              </li>
              <li
                className='cursor-pointer px-4 py-1 pb-2 text-sermons-dark hover:bg-neutral-100 hover:underline'
                onClick={() => setSearchType('Eulogies')}
                data-tooltip-id='eulogies-tooltip'
              >
                Eulogies
                <Tooltip id='eulogies-tooltip' place='right' className='z-50'>
                  Access eulogies.
                </Tooltip>
              </li>
            </ul>
          </>
        )}
        {isMySermons && (
          <>
            <ul className='grid grid-cols-1'>
              <li
                className='cursor-pointer text-sermons-dark hover:bg-neutral-100 hover:underline'
                onClick={() => setSearchType('Create')}
              >
                <Link
                  className='inline-block w-full px-4 py-1 pt-2'
                  href='/my-sermons'
                >
                  Create
                </Link>
              </li>
              <li
                className='cursor-pointer px-4 py-1 text-sermons-dark hover:bg-neutral-100 hover:underline'
                onClick={() => setSearchType('Sermons')}
              >
                Sermons
              </li>
              <li
                className='cursor-pointer px-4 py-1 text-sermons-dark hover:bg-neutral-100 hover:underline'
                onClick={() => setSearchType('Illustrations · Quotes · Humor')}
              >
                Illustrations &middot; Quotes &middot; Humor
              </li>
              <li
                className='cursor-pointer px-4 py-1 text-sermons-dark hover:bg-neutral-100 hover:underline'
                onClick={() => setSearchType('Eulogies')}
              >
                Eulogies
              </li>
              <li
                className='cursor-pointer px-4 py-1 text-sermons-dark hover:bg-neutral-100 hover:underline'
                onClick={() => setSearchType('Devotionals')}
              >
                Devotionals
              </li>
              <li
                className='cursor-pointer px-4 py-1 text-sermons-dark hover:bg-neutral-100 hover:underline'
                onClick={() => setSearchType('Articles')}
              >
                Articles
              </li>
              <li
                className='cursor-pointer px-4 py-1 text-sermons-dark hover:bg-neutral-100 hover:underline'
                onClick={() => setSearchType('Letters')}
              >
                Letters
              </li>
              <li
                className='cursor-pointer px-4 py-1 text-sermons-dark hover:bg-neutral-100 hover:underline'
                onClick={() => setSearchType('Bible Studies')}
              >
                Bible Studies
              </li>
              <li
                className='cursor-pointer px-4 py-1 text-sermons-dark hover:bg-neutral-100 hover:underline'
                onClick={() => setSearchType('All Resources')}
              >
                All Resources
              </li>
            </ul>
          </>
        )}
      </nav>
    </div>
  );
}
