'use client';

import { ReadonlyURLSearchParams, useSearchParams } from 'next/navigation';
import { Suspense, useEffect } from 'react';
import { useAppState } from '../providers/state-provider';

export default function SearchParamsTracker() {
  return (
    <Suspense>
      <ActualTracker />
    </Suspense>
  );
}

function ActualTracker() {
  const { dispatch } = useAppState();

  const searchParams = useSearchParams();
  const queryString = searchParams.toString();

  useEffect(() => {
    dispatch({ type: 'searchParamsReady', payload: true });
    dispatch({
      type: 'searchParams',
      payload: new ReadonlyURLSearchParams(searchParams),
    });
  }, [dispatch, searchParams, queryString]);

  return null;
}
