import { LectionaryWeeks, Week } from '@/types/sermons';
import React, { useEffect, useState } from 'react';
import { useAppState } from '../providers/state-provider';

interface SearchLectionaryDropdownProps {
  searchSubType: string;
  lectionaryWeeks: LectionaryWeeks | null;
  selectedWeek: string | undefined;
  setSelectedWeek: React.Dispatch<React.SetStateAction<string | undefined>>;
  week: Week | null;
}

export default function SearchLectionaryDropdown({
  searchSubType,
  lectionaryWeeks,
  selectedWeek,
  setSelectedWeek,
  week: currentWeek,
}: SearchLectionaryDropdownProps) {
  const {
    state: { lectionaryLoading },
  } = useAppState();

  const [yearWeeks, setYearWeeks] = useState(
    lectionaryWeeks?.[
      searchSubType.toLowerCase().slice(-1) as keyof LectionaryWeeks
    ],
  );

  useEffect(() => {
    setYearWeeks(
      lectionaryWeeks?.[
        searchSubType.toLowerCase().slice(-1) as keyof LectionaryWeeks
      ],
    );
  }, [lectionaryWeeks, searchSubType]);

  useEffect(() => {
    if (
      currentWeek?.cycle &&
      ['A', 'B', 'C'].includes(currentWeek.cycle) &&
      searchSubType === `Year ${currentWeek.cycle}`
    ) {
      const newSelectedWeek = yearWeeks?.find(
        (week) => week.lectionary === currentWeek?.lectionary,
      );
      if (newSelectedWeek) {
        setSelectedWeek(newSelectedWeek.number);
      }
    }
  }, [searchSubType, yearWeeks, setSelectedWeek, currentWeek]);

  const handleSelectWeek = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedWeek(e.target.value);
  };

  return (
    <div className='relative lg:col-span-6'>
      <select
        className='h-8 w-full rounded border border-neutral-300 px-2 py-1'
        onChange={handleSelectWeek}
        value={selectedWeek}
      >
        <option value=''>Lectionary Week</option>
        {!lectionaryLoading &&
          yearWeeks?.map((week) => (
            <option key={week.number} value={week.number}>
              {week.lectionary}
            </option>
          ))}
      </select>
    </div>
  );
}
