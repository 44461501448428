'use client';

import useIsPopup from '@/hooks/use-is-popup';
import { PropsWithChildren } from 'react';

export default function HideIfNotPopup({
  children,
}: PropsWithChildren<object>) {
  const isPopup = useIsPopup();
  return isPopup && children;
}
