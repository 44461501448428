import useChanged from '@/hooks/use-changed';
import { fetchChapters, fetchVerseStrings, isError } from '@/lib/helpers';
import { BibleBooks } from '@/types/sermons';
import classNames from 'classnames';
import React, { useEffect } from 'react';
import { useNotifications } from '../providers/notification-provider';

interface SearchScriptureDropdownProps {
  bibleBooks: BibleBooks | null;
  oldTestamentBook: number | '';
  setOldTestamentBook: React.Dispatch<number | ''>;
  newTestamentBook: number | '';
  setNewTestamentBook: React.Dispatch<number | ''>;
  chapters: number[];
  setChapters: React.Dispatch<React.SetStateAction<number[]>>;
  chapter: number | '';
  setChapter: React.Dispatch<number | ''>;
  verseStrings: { abbrev: string; code: string }[];
  setVerseStrings: React.Dispatch<
    React.SetStateAction<{ abbrev: string; code: string }[]>
  >;
  pericope: string;
  setPericope: React.Dispatch<string>;
  isMySermons: boolean;
}

export default function SearchScriptureDropdown({
  bibleBooks,
  oldTestamentBook,
  setOldTestamentBook,
  newTestamentBook,
  setNewTestamentBook,
  chapters,
  setChapters,
  chapter,
  setChapter,
  verseStrings,
  setVerseStrings,
  pericope,
  setPericope,
  isMySermons,
}: SearchScriptureDropdownProps) {
  const addNotification = useNotifications();

  const oldTestamentBookChanged = useChanged(oldTestamentBook, false);

  useEffect(() => {
    if (
      oldTestamentBook !== '' &&
      oldTestamentBook < 40 &&
      oldTestamentBookChanged
    ) {
      setNewTestamentBook('');
      (async () => {
        const fetchedChapters = await fetchChapters(oldTestamentBook);
        if (isError(fetchedChapters)) {
          addNotification({
            type: 'error',
            message: fetchedChapters.message,
          });
          return;
        }
        setChapters(fetchedChapters.chapters);
        if (chapter !== '' && !fetchedChapters.chapters.includes(chapter)) {
          setChapter('');
        }
      })().catch(console.error);
    }
  }, [
    addNotification,
    chapter,
    oldTestamentBook,
    oldTestamentBookChanged,
    pericope,
    setChapter,
    setChapters,
    setNewTestamentBook,
    setPericope,
    setVerseStrings,
    verseStrings,
  ]);

  const newTestamentBookChanged = useChanged(newTestamentBook, false);

  useEffect(() => {
    if (
      newTestamentBook !== '' &&
      newTestamentBook >= 40 &&
      newTestamentBookChanged
    ) {
      setOldTestamentBook('');
      (async () => {
        const fetchedChapters = await fetchChapters(newTestamentBook);
        if (isError(fetchedChapters)) {
          addNotification({
            type: 'error',
            message: fetchedChapters.message,
          });
          return;
        }
        setChapters(fetchedChapters.chapters);
        if (chapter !== '' && !fetchedChapters.chapters.includes(chapter)) {
          setChapter('');
        }
      })().catch(console.error);
    }
  }, [
    addNotification,
    chapter,
    newTestamentBook,
    newTestamentBookChanged,
    pericope,
    setChapter,
    setChapters,
    setOldTestamentBook,
    setPericope,
    setVerseStrings,
    verseStrings,
  ]);

  const chapterChanged = useChanged(chapter);

  useEffect(() => {
    if (chapterChanged) {
      setVerseStrings([]);
    }
    if (
      chapter !== '' &&
      (chapterChanged || newTestamentBookChanged || oldTestamentBookChanged)
    ) {
      const book = oldTestamentBook || newTestamentBook;
      if (book) {
        (async () => {
          const fetchedVerses = await fetchVerseStrings(book, chapter);
          if (isError(fetchedVerses)) {
            addNotification({
              type: 'error',
              message: fetchedVerses.message,
            });
            return;
          }
          setVerseStrings(fetchedVerses.verseStrings);
          if (!fetchedVerses.verseStrings.find((v) => v.code === pericope)) {
            setPericope('');
          }
        })().catch(console.error);
      }
    }
  }, [
    addNotification,
    chapter,
    chapterChanged,
    newTestamentBook,
    newTestamentBookChanged,
    oldTestamentBook,
    oldTestamentBookChanged,
    pericope,
    setPericope,
    setVerseStrings,
  ]);

  const selectOldTestament = async (
    e: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    const selectedBook = +e.target.value;
    setOldTestamentBook(selectedBook);
  };

  const selectNewTestament = async (
    e: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    const selectedBook = +e.target.value;
    setNewTestamentBook(selectedBook);
  };

  const selectChapter = async (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedChapter = +e.target.value;
    setChapter(selectedChapter);
  };

  const selectPericope = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setPericope(e.target.value);
  };

  return (
    <div className='grid grid-cols-1 gap-2 lg:col-span-6 lg:grid-cols-6'>
      <select
        className={classNames(
          'h-8 w-full rounded border border-neutral-300 px-2 py-1 lg:col-span-2',
          {
            '!border-red-700': isMySermons,
          },
        )}
        value={oldTestamentBook}
        onChange={selectOldTestament}
      >
        <option value={''}>Old Testament</option>
        {bibleBooks?.oldTestament?.map((book, index) => (
          <option key={book} value={index + 1}>
            {book}
          </option>
        ))}
      </select>
      <select
        className={classNames(
          'h-8 w-full rounded border border-neutral-300 px-2 py-1 lg:col-span-2',
          {
            '!border-red-700': isMySermons,
          },
        )}
        value={newTestamentBook}
        onChange={selectNewTestament}
      >
        <option value={''}>New Testament</option>
        {bibleBooks?.newTestament?.map((book, index) => (
          <option key={book} value={index + 40}>
            {book}
          </option>
        ))}
      </select>
      <div className='col-span-2 grid grid-cols-12 gap-2'>
        <select
          className={classNames(
            'col-span-5 h-8 w-full rounded border border-neutral-300 px-2 py-1',
            {
              'bg-neutral-300': chapters.length === 0,
              '!border-red-700': isMySermons,
            },
          )}
          disabled={chapters.length === 0}
          value={chapter}
          onChange={selectChapter}
        >
          <option value={''}>CH</option>
          {chapters.map((ch) => (
            <option key={ch} value={ch}>
              {ch}
            </option>
          ))}
        </select>
        <select
          className={classNames(
            'col-span-7 h-8 w-full rounded border border-neutral-300 py-1',
            {
              'bg-neutral-300': verseStrings.length === 0,
              '!border-red-700': isMySermons,
            },
          )}
          disabled={verseStrings.length === 0}
          value={pericope}
          onChange={selectPericope}
        >
          <option value={''}>VS</option>
          {verseStrings.map((verseString) => (
            <option key={verseString.abbrev} value={verseString.code}>
              {verseString.abbrev}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
}
